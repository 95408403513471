
<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login-page">
        <div class="form">
          <form >
            <div>
              <img src="assets/images/soft.png" alt="SoftBlobs logo" width="120" >
            </div>
            <div class="time-up-wrapper signin-heading text-center">
              <h2>Time's up!</h2>
              <p class="m-b-15">We apologize for the inconvenience, but the verification link in your email has expired.</p>
              <p>Please request a new verification link by forgot password</p>
            </div>

            <div class="container-login100-form-btn m-b-10">
              <button mat-flat-button color="primary"  class="login100-form-btn" type="submit" (click)="open()">
               Forgot-password
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="login100-more auth-banner-text" style="background-image: url('assets/images/pages/authentication-banner-regstration.png');">
       <div class="auth-banner-info">
          <p>"Our HRMS web application allows you to customize your dashboard and reports to fit your specific business need"</p>
       </div>
      </div>
    </div>
  </div>
</div>
