<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="dashboard/main">
        <img src="assets\images\soft.png" alt="" width="195" height="50">
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <li class="nav-item btnNotification" ngbDropdown>
          <!-- <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon>notifications_none</mat-icon>
            <span class="label-count bg-orange"></span>
          </button> -->
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href="#" *ngFor="let notification of notifications" onClick="return false;">
                    <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                      <span class="menu-desc">{{notification.message}}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1">Read
              All Notifications</a>
          </div>
        </li>
        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <img src={{userDetails.photoURL}} class="rounded-circle" width="32" height="32" alt="User">
            <span>{{userDetails.firstName+" "+userDetails.lastName}}</span>
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li>
                    <a routerLink="/extra-pages/profile">
                      <i class="fas fa-user"></i> Profile</a>
                    </li>
                   <li>
                      <a href="javascript:void(0);" (click)="logout()">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
