import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdvanceTable } from './advance-table.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdvanceTableService {
  private readonly API_URL = 'assets/data/advanceTable.json';
  dataChange: BehaviorSubject<AdvanceTable[]> = new BehaviorSubject<
    AdvanceTable[]
  >([]);
  userDetailsChanges : BehaviorSubject<any> = new BehaviorSubject<any>('');
  dialogData: any;
  token:any;
  isTblLoading = true;

  setHeaders(token){
    return new HttpHeaders().set('Authorization' ,token);
  }

  departments =
  {

     '0':'Administrator',
    '1':'Testing' ,
    '2':'Developer' ,
    '3':'HR'
  }
  desgination =
  {

    '0': 'Sr.Software Engineer',
    '1':'Jr.Software Engineer',
     '2': 'Team Lead',
     '3': 'Sr.Test Engineer',
      '4': 'Jr.Test Engineer',
     '5': 'Sr. Human Resource',
      '6': 'Jr. Human Resource',
      '7':'CEO'

  }
  constructor(private httpClient: HttpClient) {
    this. token = localStorage.getItem('token')
  }
  get data(): AdvanceTable[] {
    return this.dataChange.value;
  }
  getDialogData() {
    return this.dialogData;
  }
  getAllAdvanceTables(): void {
    this.httpClient.get<AdvanceTable[]>(this.API_URL).subscribe(
      (data) => {
        this.isTblLoading = true;

        this.dataChange.next(data);
      },
      (error: HttpErrorResponse) => {
        this.isTblLoading = true;

        console.log(error.name + ' ' + error.message);
      }
    );
  }
  getAllUser()
  {
    this.httpClient.get<AdvanceTable[]>(`${environment.API_DEV_URL}/user`,{headers : this.setHeaders(this.token)}).subscribe(
      (data:any) => {
        this.dataChange.next(data.data);
      },
      (error: HttpErrorResponse) => {
        console.log(error.name + ' ' + error.message);
      }
    );
  }
  addAdvanceTable(advanceTable: AdvanceTable): void {
    this.dialogData = advanceTable;
  }
  updateAdvanceTable(advanceTable: AdvanceTable): void {
    this.dialogData = advanceTable;
  }
  deleteAdvanceTable(id: number) {
    return this.httpClient.delete(`${environment.API_DEV_URL}/user/${id}/`);
  }
  getAllUserss()
  {
   return this.httpClient.get(`${environment.API_DEV_URL}/user`,{headers : this.setHeaders(this.token)});
  }

  getAllUsers(id:any)
  {
   return this.httpClient.get(`${environment.API_DEV_URL}/user/${id}/`);
  }
  SaveUser(empformdata:any)
  {

   return this.httpClient.post(`${environment.API_DEV_URL}/user/signup`,empformdata);
  }
  updateuser(empformdata:any , userId:any){

    return this.httpClient.patch(`${environment.API_DEV_URL}/user/${userId}`,empformdata);

  }
  status()
  {
    return this.httpClient.get(`${environment.API_DEV_URL}/user/status`);

  }
}
