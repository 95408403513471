<div>
  <aside id="leftsidebar" class="sidebar" (mouseenter)="mouseHover($event)" (mouseleave)="mouseOut($event)">
    <div class="menu">
      <ul class="list"
        [ngStyle]="{'position' : 'relative', 'max-height' : listMaxHeight+'px', 'max-width' : listMaxWidth+'px'}"
        [perfectScrollbar]>
        <li class="sidebar-user-panel">
          <div class="user-panel">
            <div class=" image">
              <img [src]="userDetails.photoURL ? userDetails.photoURL : null" class="img-circle user-img-circle" alt="User Image" />
            </div>
          </div>
          <div class="profile-usertitle">
            <div class="sidebar-userpic-name">{{userDetails.firstName+" "+userDetails.lastName }}</div>
            <div class="profile-usertitle-job ">{{userDetails.desgination}}</div>
          </div>
          <div class="sidebar-userpic-btn">
            <a mat-icon-button disabled routerLink="extra-pages/profile" matTooltip="Profile"
              [matTooltipPosition]="'above'">
              <i class="material-icons-two-tone">account_circle</i>
            </a>

            <a mat-icon-button disabled routerLink="calendar" matTooltip="Calendar" [matTooltipPosition]="'above'">
              <i class="material-icons-two-tone">event</i>
            </a>
            <a href="javascript:void(0);" (click)="logout()"matTooltip="Logout" [matTooltipPosition]="'above'">
              <i class="material-icons-two-tone">logout</i>
            </a>
          </div>
        </li>
        <ng-container *ngFor="let sidebarItem of sidebarItems">
        <li [routerLinkActive]="sidebarItem.submenu.length != 0 ? 'active' : ''" *ngIf="sidebarItem.role.includes(userRole)">

            <div class="header" *ngIf="sidebarItem.groupTitle === true">{{sidebarItem.title}}</div>
            <a [routerLink]="sidebarItem.class === '' ? [sidebarItem.path] : null" *ngIf="!sidebarItem.groupTitle;"
              [ngClass]="[sidebarItem.class]" (click)="callToggleMenu($event, sidebarItem.submenu.length)"
              class="menu-top">
              <i [ngClass]="[sidebarItem.iconType]">{{sidebarItem.icon}}</i>
              <span class="hide-menu">{{sidebarItem.title}}
              </span>
              <span *ngIf="sidebarItem.badge != '' " [ngClass]="[sidebarItem.badgeClass]">{{sidebarItem.badge}}</span>
            </a>
            <ul class="ml-menu" *ngIf="sidebarItem.submenu.length > 0">
              <li *ngFor="let sidebarSubItem1 of sidebarItem.submenu"
                [routerLinkActive]="sidebarSubItem1.submenu.length > 0 ? '' : 'active'">
                <a [routerLink]="sidebarSubItem1.submenu.length > 0 ? null : [sidebarSubItem1.path]"
                  (click)="callToggleMenu($event,sidebarSubItem1.submenu.length)" [ngClass]="[sidebarSubItem1.class]">
                  {{sidebarSubItem1.title}}
                </a>
                <ul class="ml-menu-2" *ngIf="sidebarSubItem1.submenu.length > 0">
                  <li *ngFor="let sidebarSubItem2 of sidebarSubItem1.submenu"
                    [routerLinkActive]="sidebarSubItem2.submenu.length > 0 ? '' : 'active'">
                    <a [routerLink]="sidebarSubItem2.submenu.length > 0 ? null : [sidebarSubItem2.path]"
                      (click)="callToggleMenu($event,sidebarSubItem2.submenu.length)" [ngClass]="[sidebarSubItem2.class]">
                      {{sidebarSubItem2.title}}
                    </a>
                    <ul class="ml-menu-3" *ngIf="sidebarSubItem2.submenu.length > 0">
                      <li *ngFor="let sidebarSubItem3 of sidebarSubItem2.submenu"
                        [routerLinkActive]="sidebarSubItem3.submenu.length > 0 ? '' : 'active'">
                        <a [routerLink]="sidebarSubItem3.submenu.length > 0 ? null : [sidebarSubItem3.path]"
                          (click)="callToggleMenu($event,sidebarSubItem3.submenu.length)"
                          [ngClass]="[sidebarSubItem3.class]">
                          {{sidebarSubItem3.title}}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
        </li>
      </ng-container>
      </ul>
    </div>
  </aside>
</div>
