<div class="limiter">
  <div class="container-login100">
    <div class="wrap-login100">
      <div class="login-page">
        <div class="form">
          <form>
            <div>
              <img src="assets/images/soft.png" alt="SoftBlobs logo" width="120" >
            </div>
            <div class="time-up-wrapper signin-heading text-center">
              <h2>Your registration already confirmed</h2>
              <p class="m-b-15">Please try to login to continue the process.</p>
            </div>
            <div>
              <a routerLink="/authentication/signin" class="txt1">
                <mat-icon class="mat-cstm-icon">keyboard_backspace</mat-icon>  Log in
              </a>
            </div>

          </form>
        </div>
      </div>

      <div class="login100-more auth-banner-text" style="background-image: url('assets/images/pages/authentication-banner-regstration.png');">
       <div class="auth-banner-info">
          <p>"Our HRMS web application allows you to customize your dashboard and reports to fit your specific business need"</p>
       </div>
      </div>
    </div>
  </div>
</div>

