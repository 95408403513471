import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  selectedUser: any;
  editSelectedUser: any;

  // constructor(private http: HttpClient) {
  //   this.currentUserSubject = new BehaviorSubject<User>(
  //     JSON.parse(localStorage.getItem('currentUser'))
  //   );
  //   this.currentUser = this.currentUserSubject.asObservable();
  // }
  constructor(public http: HttpClient) {
    let currentUserName = localStorage.getItem('currentUsers')
    console.log('swapna hi',localStorage.getItem('currentUsers'));
    this.currentUserSubject = new BehaviorSubject<User>(currentUserName as any);
    console.log('try pls',this.currentUserSubject );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  login(username: string, password: string) {
    return this.http
      .post<any>(`${environment.API_DEV_URL}/authenticate`, {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    return of({ success: false });
  }

  loginUser(userCredentials:any){
    const routeLink = `${environment.API_DEV_URL}/auth/sign-in`;
    return this.http.post(routeLink , userCredentials)
  }
  signUp(userData:any)
  {
    const routeLink = `${environment.API_DEV_URL}/auth/sign-up`;
    return this.http.post(routeLink , userData)

  }
  confirmUser(userdata:any):Observable<any>{
    const routeLink = `${environment.API_DEV_URL}/auth/confirm-email`;
    var data=this.http.post(routeLink ,userdata)
    console.log("confirmUser",data)
    return data
   }
   refreshAccess(userdata:any):Observable<any>{
    const routeLink = `${environment.API_DEV_URL}/auth/refresh-access`;
    return this.http.post(routeLink ,userdata)
   }

  updatePassword(passwordCredentials:any,id:any){
    const routeLink = `${environment.API_DEV_URL}/user/reset/${id}`;
    return this.http.patch(routeLink , passwordCredentials)
  }
  passwordReset(userdata:any):Observable<any>{
    const routeLink = `${environment.API_DEV_URL}/auth/reset-password`;
    return this.http.post(routeLink ,userdata)
   }
   forgotPasswordReset(userdata:any):Observable<any>{
    const routeLink = `${environment.API_DEV_URL}/auth/forgot-password`;
    return this.http.post(routeLink ,userdata)
   }

   linkExpireStatus(link : any){
    return this.http.post(`${environment.API_DEV_URL}/user/linkExpires` , {link})
   }

}
